import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby"


export default function Other ({ data }) {
	const conferences = data.conferences.edges[0].node.childServicesJson.conferences;
	const teaching = data.teaching.edges[0].node.childServicesJson.teaching;

	return (
		<Layout>
			<SEO title="Other" />

			<h2 className="text-center">Reviewing</h2>

			<div className="container text-center mb-5">
				{conferences.map(conference => (
					<div className="row my-2 py-2 mx-5">
						<div className="container">
							<div className="row">
								<div className="col-10 mx-auto">
									<div className="fs-4 lead" style={{ fontWeight: 2000 }}>
										{conference.name_short}
									</div>
								</div>
							</div><div className="row">
								<div className="col-12 fs-5">
									<div >
										{conference.name}
									</div>
								</div>
							</div>
							<div className="row text-start">

								<div className="col-md-4 col-12 mx-auto">
									<ul className="d-flex flex-column-reverse mt-2 text-justified">
										{conference.years.map(year => (
											<li className="my-1"><b>{year.year}: {year.comment}</b></li>
										))}
									</ul>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-12 mx-auto border-bottom">

								</div>
							</div>
						</div>

					</div>
				))}
			</div>

			<h2 className="text-center mt-5">Teaching</h2>

			<div className="container text-center mb-5">
				{teaching.map(course => (
					course.semesters.map(term => (
						<div className="row my-3 mx-5">
							<div className="col-10 mx-auto">
								<span className="fw-bold">{course.name} ({course.name_short})</span> - {term.role} - {term.semester} {term.comment ? "(" + term.comment + ")" : ""}
							</div>
						</div>
					))

				))}
			</div>
		</Layout>
	);
}


export const query = graphql`
query Other {
	  teaching: allFile(filter: {name: {eq: "teaching"}}) {

    edges {
      node {
        childServicesJson {
          teaching {
            name
            name_short
            semesters {
            	semester
            	role
            	comment
            }
          }
        }
      }
    }
  }
  conferences: allFile(filter: {name: {eq: "reviewing"}}) {
    edges {
      node {
        childServicesJson {
          conferences {
            name
            name_short
            years {
            	year
            	comment
            }
          }
        }
      }
    }
  }
}

`

